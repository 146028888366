import React from "react";
import { SortBy } from "../../../types";

export default function Select(props :{
    options : {value : string, label : string}[],
    value : string,
    setFocus : (bool: boolean) => void,
    isOpen : boolean,
    setSortBy? : (v : SortBy) => void
    setValue? : (value : string) => void
}){
    function getCurrentValueLabel(value : string){
        const option = props.options.find(o => o.value === value);
        return option ? option.label : "";
    }
    function getClassOfLabel(value : string){
        if(value === "all" || value === "" || value === "date"){
            return "";
        } else {
            return '--selected';
        }
    }
    function getClassOfInput(){
        return props.isOpen ? "focus" : "";
    }
    function getClassOfOption(value: string){
        if(value === props.value){
            return "selected";
        } else {
            return "";
        }
    }
    return (
        <div className="ouidouSelect">
            <div className={`ouidouSelect__input ${getClassOfInput()}`} onClick={()=>props.setFocus(!props.isOpen)}>
                <span className={`ouidouSelect__input--value${getClassOfLabel(props.value)}`}>
                    {getCurrentValueLabel(props.value)}
                </span>
            </div>
            <div className="ouidouSelect__options" >
                {props.options.map(option => (
                    <div className={"ouidouSelect__options--item " + getClassOfOption(option.value)} key={option.value}  onClick={() => {
                        props.setFocus(false);
                        if(props.setValue){
                            props.setValue(option.value);
                        }
                        if(props.setSortBy){
                            props.setSortBy(option.value as SortBy);
                        }
                    }}>
                        <span className="ouidouSelect__options--item--value">
                            {option.label}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
} 