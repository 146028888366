import { Job } from "./types";

const base_url = "https://ouidou.jobs.beetween.fr/";

export async function authenticate(){
    const client_id = process.env.REACT_APP_CLIENT_ID;
    const client_secret = process.env.REACT_APP_CLIENT_SECRET;
    const url = base_url+"oauth/v2/token";
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            grant_type: 'client_credentials',
            client_id: client_id,
            client_secret: client_secret
        })
    }
    // fetch the api with axios to authenticate and save the token
    await fetch(url, options)
        .then(response => response.json())
        .then(data => {
            // save the token in local storage
            localStorage.setItem('token', data.access_token);
        }
        ).catch(error => console.log(error));
}
export async function getToken(){
    // get the token from local storage or authenticate if it doesn't exist
    let token = localStorage.getItem('token');
    if(token === null){
        await authenticate();
        token = localStorage.getItem('token');
    }
    return token;
}

export async function getJobs(){
    const token = await getToken();
    const url = base_url + "api/job/list?rows=999";
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    let jobs : Job[] = [];
    // fetch the api with axios to get the jobs with  async/await
    await fetch(url, options)
        .then(response => response.json())
        .then(data => {
            jobs = data.jobs;
        }
        ).catch(error => console.log(error));
    return jobs
}

export function getTypesByJobs(jobs : Job[]){
    let types : { value: string; label: string; }[] = [{ value: "all", label: "Tous les types" }];
    for(let job of jobs){
        let type = { value: job.contractType, label: job.contractType };
        if(!types.find(t => t.value === type.value)){
            types.push(type);
        }
    }
    return types;
}

export function getLocationsByJobs(jobs : Job[]){
    let locations : { value: string; label: string; }[] = [{ value: "all", label: "Toutes les agences" }];
    for(let job of jobs){
        let locationObj = { value: job.city, label: job.city };
        if(!locations.find(location => location.value === locationObj.value)){
            locations.push(locationObj);
        }
    }
    return locations
}
