import React from "react";
// import { authenticate } from "./Api";
import { Job, SelectFocus, SortBy } from "./types";

const Jobs : Job[] = []
export const AppContext = React.createContext({
    jobsContext: {
        jobs: Jobs,
        setJobs: (jobs: Job[]) => {},
        apiJobs: Jobs,
        setApiJobs: (jobs: Job[]) => {}
    },
    filtersContext: {
        type : "",
        setType: (type: string) => {},
        location: "",
        setLocation: (location: string) => {},
        search: "",
        setSearch: (search: string) => {},
        sortBy: "",
        setSortBy: (sortBy: SortBy) => {},
    },
    focusContext: {
        focus: "",
        setFocus: (focus: SelectFocus) => {},
    },
    authenticateContext: {
        authenticated: false,
        setAuthenticated: (authenticated: boolean) => {},
        loading: false,
        setLoading: (loading: boolean) => {},
    }
});
