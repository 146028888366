import React from "react";
import { Job } from "../../types";
import LinkImage from "../../assets/icons/link.png"
import PenEmoji from "../../assets/emoji/pen.png"
import PinEmoji from "../../assets/emoji/pin.png"
import { AppContext } from "../../store";
import { ClipLoader } from "react-spinners";


export default function Jobs(props:{jobs: Job[]}){
    const {authenticateContext} = React.useContext(AppContext);
    const {loading} = authenticateContext;

    return (
        <div className="jobs">
            {props.jobs.length > 0 && props.jobs.map(job => (
                <a href={job.url} target="_blank" className={"jobs__item " + job.tech} key={job.id} rel="noreferrer">
                    <div className="jobs__item--title">
                        <span className={"job__item--tag " + job.tech}></span>
                        {job.title}
                    </div>
                    <div className="jobs__item--footer">
                        <div className="jobs__item--footer--infos">
                            <span className="jobs__item--footer--item"><img src={PenEmoji} alt="" className="emoji" /> {job.contractType}</span>
                            <span className="jobs__item--footer--item"><img src={PinEmoji} alt="" className="emoji" /> {job.city}</span>
                        </div>
                        <div className="jobs__item--footer--link">
                                <img src={LinkImage} alt="Voir plus" />
                        </div>
                    </div>
                </a>
            ))}
            {
                !loading && props.jobs.length === 0 && (
                    <h3>Aucune annonce disponible</h3>
                )
            }
            {
                loading && (
                    <div className="loading">
                        <ClipLoader loading={loading} speedMultiplier={1.5} color={"#2962ff"} />
                    </div>
                )
            }
        </div>

    )
}
