import React, { useEffect } from "react";
import { getLocationsByJobs, getTypesByJobs } from "../../Api";
import { AppContext } from "../../store";
import { SelectFocus, SortBy, SortByOptions } from "../../types";
import Search from "./Search/Search";
import Select from "./Select/Select";

export default function Filters(){
    const {filtersContext, focusContext, authenticateContext, jobsContext} = React.useContext(AppContext);
    const {type, setType, location, setLocation, search, setSearch, sortBy, setSortBy} = filtersContext;
    const {focus, setFocus} = focusContext;
    const {apiJobs} = jobsContext;
    const {authenticated} = authenticateContext;
    const [locations, setLocations] = React.useState<{ value: string; label: string; }[]>([]);
    const [types, setTypes] = React.useState<{ value: string; label: string; }[]>([]);
    const sortByOptions : SortByOptions = [
        { value: "date", label: "Derniers postes" },
        { value: "az", label: "A-Z" },
        { value: "za", label: "Z-A" }
    ]
    useEffect(()=>{
        if(!authenticated || apiJobs.length === 0) return;
        setLocations(getLocationsByJobs(apiJobs));
        setTypes(getTypesByJobs(apiJobs));
        
    }, [authenticated, apiJobs])

    function editFocus(focus: SelectFocus, isOpen: boolean){
        isOpen ? setFocus(focus) : setFocus("");
    }

    return (
        <div className="Filters">
            <div className="selectWrapper">
                <Select options={sortByOptions} value={sortBy} setFocus={(b)=>editFocus("sort-by", b)} isOpen={focus === "sort-by"} setSortBy={(v:SortBy) => setSortBy(v)}/>
                <Select options={locations} value={location} setFocus={(b)=>editFocus("location", b)} isOpen={focus === "location"} setValue={(v:string) => setLocation(v)}/>
                <Select options={types} value={type} setFocus={(b)=>editFocus("type", b)} isOpen={focus === "type"} setValue={(v:string) => setType(v)}/>
            </div>
            <Search search={search} setSearch={setSearch} placeholder="Rechercher par mots clés" />
        </div>
    )
}
