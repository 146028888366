import React from 'react';
import './App.scss';
import Filters from './components/Filters/Filters';
import Jobs from './components/Jobs/Jobs';
import { Job, SelectFocus, SortBy } from './types';
import { AppContext } from './store';
import { authenticate, getJobs } from './Api';

function App() {
  const [apiJobs, setApiJobs] = React.useState<Job[]>([]);
  const [jobs, setJobs] = React.useState<Job[]>([]);
  const [location, setLocation] = React.useState<string>("all");
  const [type, setType] = React.useState<string>("all");
  const [search, setSearch] = React.useState<string>("");
  const [sortBy, setSortBy] = React.useState<SortBy>("date");
  const [focus, setFocus] = React.useState<SelectFocus>("");
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  const jobsValue = {jobs, setJobs, apiJobs, setApiJobs};
  const filtersValue = {type, setType, location, setLocation, search, setSearch, sortBy, setSortBy};
  const focusValue = {focus, setFocus};
  const authenticateValue = {authenticated, setAuthenticated, loading, setLoading};

  const appValues = {
    jobsContext : jobsValue,
    filtersContext : filtersValue,
    focusContext : focusValue,
    authenticateContext : authenticateValue
  };

  React.useEffect(()=>{
    authenticate().then(()=>{
        // console.log("Authenticated");
        setAuthenticated(true);
        getJobs().then((j)=>{
            setApiJobs(j);
            setLoading(false);
        }
        ).catch((err)=>{
            console.log(err);
        })
        }
    ).catch(()=>{
        console.log("Not authenticated");
    }
    );
  }, [])

    React.useEffect(()=>{
      if(!authenticated) return;
        const filteredJobs = apiJobs.filter((job)=>{
            if(type !== "all" && location === "all"){
                return job.contractType === type;
            }
            if(location !== "all" && type === "all"){
                return job.city === location;
            }
            if (location !== "all" && type !== "all"){
                return job.city === location && job.contractType === type;
            }
            if(search !== ""){
                return job.title.toLowerCase().includes(search.toLowerCase());
            }
            return true;
        }
        );
        if(sortBy === "date"){
            filteredJobs.sort((a,b)=>{
              let aDate = new Date(a.creationDate);
              let bDate = new Date(b.creationDate);
              return bDate.getTime() - aDate.getTime();
            })
        } else if(sortBy === "az"){
            filteredJobs.sort((a,b)=>{
                return a.title.localeCompare(b.title);
            })
        } else if(sortBy === "za"){
            filteredJobs.sort((a,b)=>{
                return b.title.localeCompare(a.title);
            })
        }
        setJobs(filteredJobs);
    }
    , [authenticated, apiJobs, type, location, search, sortBy])

  function clickHandler(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.target as HTMLDivElement;
    
    if(!target.classList.contains("ouidouSelect__input")) {
    setFocus("");
    }
  }
  return (
    <AppContext.Provider value={appValues}>
    <div className="App" onClick={clickHandler}>
      <Filters/>
      <Jobs jobs={jobs}/>
    </div>
    </AppContext.Provider>
  );
}

export default App;
