import React from "react";

export default function Search(props:{
    search: string,
    setSearch: (search: string) => void,
    placeholder: string,
}){
    function getClassOfLabel(){
        return props.search !== "" ? "focus" : "";
    }
    return (
        <div className="ouidouSearch">
        <div className="ouidouSearch__wrapper">
            <input 
                className={`ouidouSearch__input ${getClassOfLabel()}`} 
                value={props.search}
                placeholder={props.placeholder}
                onChange={(e) => props.setSearch(e.target.value)}
            />
        </div>
    </div>
)
}